import { useEffect, useRef } from 'react';

import { Grid, Stack } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { track } from '../../../services/analytics';
import { EVENT_EXECUTIVE_SUMMARY_PAGE, PGV_exec_summary } from '../../../services/analytics/events';
import { projectState } from '../../../stores/project';

import Crediting from './components/Crediting';
import Location from './components/Location';
import { ProjectAttributes } from './components/projectAttributes/ProjectAttributes';
import ProjectComparison from './components/ProjectComparison';
import { ProjectInfo } from './components/ProjectInfo';
import ProjectSize from './components/ProjectSize';
import RatingCard from './components/RatingCard';
import RelatedContent from './components/RelatedContent';

export const ExecutiveSummary = () => {
  const { projectData, projectCreditingData } = useRecoilValue(projectState);
  const isPageTracked = useRef<boolean>(false);

  useEffect(() => {
    if (projectData && !!projectCreditingData?.issuanceSizeCategory && !isPageTracked.current) {
      isPageTracked.current = true;
      track(PGV_exec_summary, EVENT_EXECUTIVE_SUMMARY_PAGE, {
        project_id: projectData?.projectId,
        project_name: projectData?.title,
        project_category: projectData?.projectCategory,
        project_type: projectData?.projectType,
        issuance_size: projectCreditingData?.issuanceSizeCategory,
        ghg_rating: projectData?.ghgRatingDetail?.grade,
        sdgRating: projectData?.sdgRatingDetail?.grade,
      });
    }
  }, [projectData, projectCreditingData]);

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      columns={{ xs: 4, lg: 12 }}
    >
      <Grid xs={8}>
        <Stack gap={3}>
          <Stack direction={'row'} gap={3} sx={{ display: { xs: 'flex', lg: 'none' } }}>
            <RatingCard
              rating={projectData?.ghgRatingDetail?.grade || ''}
              percentage={projectData?.ghgRatingDetail?.percentage || 0}
              title='GHG Rating'
              path='/ghg_rating'
              type='ghg'
              flex={1}
            />
            <RatingCard
              rating={projectData?.sdgRatingDetail?.grade || ''}
              percentage={projectData?.sdgRatingDetail?.percentage || 0}
              sdgImpact={projectData?.sdgImpact || ''}
              title='SDG Rating'
              path='/sdg_rating'
              type='sdg'
              flex={1}
            />
          </Stack>
          <ProjectInfo />
          <ProjectAttributes />
          <Grid container justifyContent={'space-between'}>
            <Grid xs={5.8}>
              <Crediting />
            </Grid>
            <Grid xs={5.8}>
              <ProjectSize />
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid xs={4}>
        <RatingCard
          rating={projectData?.ghgRatingDetail?.grade || ''}
          percentage={projectData?.ghgRatingDetail?.percentage || 0}
          title='GHG Rating'
          path='/ghg_rating'
          type='ghg'
          display={{ xs: 'none', lg: 'flex' }}
        />
        <RatingCard
          rating={projectData?.sdgRatingDetail?.grade || ''}
          percentage={projectData?.sdgRatingDetail?.percentage || 0}
          sdgImpact={projectData?.sdgImpact || ''}
          title='SDG Rating'
          path='/sdg_rating'
          type='sdg'
          display={{ xs: 'none', lg: 'flex' }}
        />
        <RelatedContent />
        <ProjectComparison />
        <Location />
      </Grid>
    </Grid>
  );
};
